/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.1/dist/jquery.min.js
 * - /npm/jquery-ui@1.13.2/dist/jquery-ui.min.js
 * - /npm/jquery-color@2.2.0/dist/jquery.color.min.js
 * - /npm/uikit@3.15.8/dist/js/uikit.min.js
 * - /npm/uikit@3.15.8/dist/js/uikit-icons.min.js
 * - /npm/jsrender@1.0.11/jsrender.min.js
 * - /npm/lodash@4.17.21/lodash.min.js
 * - /npm/video.js@7.21.0/dist/video.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
